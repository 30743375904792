import React from 'react';
import styles from '../styles/payment.module.scss';
import 'antd/dist/antd.css';
import {message, Spin, Modal, Result, Switch} from 'antd';
import copy from 'copy-to-clipboard';
import StorageUtils from "../tools/StorageUtils";
import BaseComponent from "../components/BaseComponent";
import WebServerRestClient from "../tools/WebServerRestClient";
import QRCode from "qrcode.react";

let timer;
export default class CryptoCoinPayment extends BaseComponent {


    constructor(props) {
        super(props);
        this.state = {
            orderId: '',
            qrcodeUrl: '',
            receiptAddress: '',
            tokenAmount: 0,
            leftTime: '00:00:00',
            showOrderResultModal: false
        }
    }

    componentDidMount() {
        this.loadOrder();
        setInterval(() => {
            this.loadOrder();
        }, 60 * 1000);
    }

    loadOrder() {
        this.setState({loading: true});
        new WebServerRestClient().GET('/user/payment/crypto-orders/' + this.props.match.params.orderId, {
        }).then(response => {
            console.debug("create-eth-order response -> " + JSON.stringify(response));
            this.setState({loading: false});
            if (response.code === 200) {
                let qrcodeUrl = 'ethereum:'
                    + response.data.receiptAddress
                    + '?decimal=18&value=' + response.data.tokenAmountWei;

                this.setState({
                    orderId: response.data.orderId,
                    blockchain: response.data.blockchain,
                    tokenName: response.data.tokenName,
                    tokenAmount: response.data.tokenAmount,
                    tokenPrice: response.data.tokenPrice,
                    receiptAddress: response.data.receiptAddress,
                    tokenAmountWei: response.data.tokenAmountWei,
                    leftSeconds: response.data.leftSeconds,
                    qrcodeUrl: qrcodeUrl
                });

                if (response.data.paymentStatus === 1) {
                    clearInterval(timer);
                    timer = setInterval(() => {
                        this.leftTime();
                    }, 1000);
                } else if (response.data.paymentStatus === 2) {
                    this.setState({
                        showOrderResultModal: true,
                        leftTime: '00:00:00',
                        orderResultIcon: 'success',
                        orderResultTitle: window.language.payment_modal.crypto_order_success_title,
                        orderResultMessage: window.language.payment_modal.crypto_order_success,
                    });
                    clearInterval(timer);
                } else if (response.data.paymentStatus === 3) {
                    this.setState({
                        showOrderResultModal: true,
                        leftTime: '00:00:00',
                        orderResultIcon: 'warning',
                        orderResultTitle: window.language.payment_modal.crypto_order_timeout_title,
                        orderResultMessage: window.language.payment_modal.crypto_order_timeout,
                    });
                } else if (response.data.paymentStatus === 4) {
                    this.setState({
                        showOrderResultModal: true,
                        leftTime: '00:00:00',
                        orderResultIcon: 'warning',
                        orderResultTitle: window.language.payment_modal.crypto_order_canceled_title,
                        orderResultMessage: window.language.payment_modal.crypto_order_canceled,
                    });
                    clearInterval(timer);
                }
            } else if (response.code === 401 || response.code === 403) {
                message.warn("Please Log In!");
                StorageUtils.remove(window.constants.CURRENT_USER);
                this.props.history.push('/');
            } else {
                message.warn(response['msg'])
            }
        }).catch(err => {
            this.setState({loading: false});
            message.error("Error: " + err.message);
        });
    }

    leftTime() {
        let leftSeconds = this.state.leftSeconds;
        if (leftSeconds > 0) {
            let hours = Math.floor(leftSeconds / 60 / 60 % 24) < 10 ? `0${Math.floor(leftSeconds / 60 / 60 % 24)}` : Math.floor(leftSeconds / 60 / 60 % 24);
            let minutes = Math.floor(leftSeconds / 60 % 60) < 10 ? `0${Math.floor(leftSeconds / 60 % 60)}` : Math.floor(leftSeconds / 60 % 60);
            let seconds = Math.floor(leftSeconds % 60) < 10 ? `0${Math.floor(leftSeconds % 60)}` : Math.floor(leftSeconds % 60);

            this.setState({
                leftTime: `${hours}:${minutes}:${seconds}`,
                leftSeconds: leftSeconds - 1
            });
        } else {
            this.setState({
                showOrderResultModal: true,
                leftTime: '00:00:00',
                orderResultIcon: 'warning',
                orderResultTitle: window.language.payment_modal.crypto_order_timeout_title,
                orderResultMessage: window.language.payment_modal.crypto_order_timeout,
            });
        }
    }

    copyTokenAmount(){
        copy(this.state.tokenAmount);
        message.success('Copied!');
    }

    copyWalletAddress(){
        copy(this.state.receiptAddress);
        message.success('Copied!');
    }

    render() {
        return (
            <div className={styles.container}>
                <Spin tip="加载中(Loading)" spinning={this.state.loading}>
                    <div className={styles.form_center}>
                        <div className={styles.language_switch_section}>
                            <Switch checkedChildren="中文" unCheckedChildren="EN"
                                    defaultChecked={StorageUtils.getLanguage() === window.constants.LANGUAGE_CN}
                                    className={styles.language_switch}
                                    onChange={(value) => {
                                        this.switchLanguage(value);
                                    }}/>
                        </div>
                        <div className={styles.account_center}>
                            <QRCode className={styles.step_image} value={this.state.qrcodeUrl} size={180}/>
                            <div className={styles.step_desc}>
                                <table className={styles.step_desc_table}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            {window.language.payment_modal.crypto_type} <span style={{color: '#c758d6'}}>{this.state.tokenName}.{this.state.blockchain}</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {window.language.payment_modal.crypto_price} <span style={{color: '#090B34'}}>$ {this.state.tokenPrice}</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {window.language.payment_modal.crypto_amount} <span style={{color: '#c758d6'}}>{this.state.tokenAmount}</span>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <button className={styles.copy_button} onClick={this.copyTokenAmount.bind(this)}>
                                             {window.language.payment_modal.copy}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={styles.payment_address}>
                                            {window.language.payment_modal.address} <span style={{}}>{this.state.receiptAddress}</span>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <button className={styles.copy_button} onClick={this.copyWalletAddress.bind(this)}>
                                               {window.language.payment_modal.copy}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {window.language.payment_modal.time_left} <span style={{}}>{this.state.leftTime}</span>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <br/>
                            </div>
                        </div>

                        <div className={styles.payment_tip}>
                            <div className={styles.notice_title}>{window.language.payment_modal.crypto_steps_title}</div>
                            <div className={styles.notice_content}>
                                {window.language.payment_modal.crypto_steps_1}
                            </div>
                            <div className={styles.notice_content}>
                                {window.language.payment_modal.crypto_steps_2}
                            </div>

                            <br/>
                            <br/>
                            <div className={styles.notice_title}>{window.language.payment_modal.crypto_notice_title}</div>
                            <div className={styles.notice_content}>
                                {window.language.payment_modal.crypto_notice_1_1}<span style={{color: '#c758d6', fontWeight: '500'}}>{window.language.payment_modal.crypto_notice_1_2}</span>{window.language.payment_modal.crypto_notice_1_3}
                            </div>
                            <div className={styles.notice_content}>
                                {window.language.payment_modal.crypto_notice_2_1}<span style={{color: '#c758d6', fontWeight: '500'}}>{window.language.payment_modal.crypto_notice_2_2}</span>{window.language.payment_modal.crypto_notice_2_3}
                            </div>
                            <div className={styles.notice_content}>
                                {window.language.payment_modal.crypto_notice_3}
                            </div>
                            <div className={styles.notice_content}>
                                {window.language.payment_modal.crypto_notice_4}
                                <span style={{color: '#6179f8', fontWeight: '500'}}>{window.constants.SUPPORT_EMAIL}</span>
                            </div>

                        </div>
                    </div>
                </Spin>

                <Modal
                    bodyStyle={{padding: 0}}
                    closable={false}
                    keyboard={false}
                    maskClosable={false}
                    destroyOnClose={true}
                    footer={null}
                    visible={this.state.showOrderResultModal}
                    onCancel={() => this.props.onRequestClose()}>
                    <div className={styles.container}>
                        <Result
                            status={this.state.orderResultIcon}
                            title= {this.state.orderResultTitle}
                            subTitle= {this.state.orderResultMessage}
                        />
                    </div>
                </Modal>

            </div>
        );
    }


}


