import React from 'react';
import styles from '../styles/user-node-list.module.scss';
import 'antd/dist/antd.css';
import {Icon, List, message, Modal, Spin} from 'antd';
import copy from 'copy-to-clipboard';
import BaseComponent from "../components/BaseComponent";
import WebServerRestClient from "../tools/WebServerRestClient";
import StorageUtils from "../tools/StorageUtils"
import { PageHeader, Button, Descriptions } from 'antd';
import CommonUtils from "../tools/CommonUtils"
import QRCode from "qrcode.react";

export default class UserNodeList extends BaseComponent {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            doing: false,
            showResetModal: false,
            account: {},
            dataList: [],
            orderId: '',
            qrcodeUrl: '',
            receiptAddress: '',
            tokenAmount: 0,
            leftTime: '00:00:00'
        }
    }

    componentDidMount() {
        this.loadUserDetails();
        // this.loadNodeList();
    }

    loadUserDetails() {
        this.setState({loading: true})
        new WebServerRestClient().GET('/user/nodes/' + this.props.match.params.userId)
            .then(response => {
                this.setState({loading: false})
                if (response.code === 200) {
                    this.setState({account: response.data})
                } else if (response.code === 401 || response.code === 403) {
                    message.warn("Please Log In!")
                    StorageUtils.remove(window.constants.CURRENT_USER)
                    this.props.history.push('/')
                } else {
                    message.warn(response['msg'])
                }
            }).catch(err => {
            this.setState({loading: false})
            message.error("Error: " + err.message)
        })
    }

    loadNodeList() {
        this.setState({loading: true})
        new WebServerRestClient().GET('/user/node-list/' + this.props.match.params.userId)
            .then(response => {
                this.setState({loading: false})
                if (response.code === 200) {
                    this.setState({dataList: response.data.nodes})
                } else if (response.code === 401 || response.code === 403) {
                    message.warn("Please Log In!")
                    StorageUtils.remove(window.constants.CURRENT_USER)
                    this.props.history.push('/')
                } else {
                    message.warn(response['msg'])
                }
            }).catch(err => {
            this.setState({loading: false})
            message.error("Error: " + err.message)
        })
    }

    resetSubscribe = () =>  {
        this.setState({showResetModal: false})
        this.setState({loading: true});
        new WebServerRestClient().GET('/user/nodes/reset-subscribe/' + this.state.account.subscribeId)
            .then(response => {
                this.setState({loading: false})
                if (response.code === 200) {
                    this.loadUserDetails()
                } else {
                    message.warn(response['msg'])
                }
            }).catch(err => {
            this.setState({loading: false})
            message.error("Error: " + err.message)
        })
    }

    copyText = (text) => {
        copy(text);
        message.success(window.language.copy_successfully);
    };

    showQrcode = (shareLink) => {
        this.setState({qrcodeUrl: shareLink, showQrcodeModal: true})
    };


    render() {
        return (
            <div className={styles.container}>
                <Spin spinning={this.state.loading}>
                    <div className={styles.container}>
                        <div className={styles.space}/>
                        <div>
                            <Descriptions size="small" column={2}>
                                <Descriptions.Item label={window.language.subscribe.data_quota}>{this.state.account.quota}</Descriptions.Item>
                                <Descriptions.Item label={window.language.subscribe.data_balance}>{this.state.account.balance}</Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div>
                            <Descriptions size="small" column={2}>
                                <Descriptions.Item label={window.language.subscribe.data_download}>{this.state.account.download}</Descriptions.Item>
                                <Descriptions.Item label={window.language.subscribe.data_upload}>{this.state.account.upload}</Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div>
                            <Descriptions size="small" column={2}>
                                <Descriptions.Item label={window.language.expire_time}>
                                    {CommonUtils.formatDate(new Date(this.state.account.expireTime), 'YYYY-MM-DD')}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div>
                            <List.Item
                                actions={[
                                    <a key="list-loadmore-edit" onClick={() => this.copyText(this.state.account.subscribeUrl)}>{window.language.copy}</a>,
                                    <a key="list-loadmore-more" onClick={() => this.showQrcode(this.state.account.subscribeUrl)}>{window.language.qrcode}</a>,
                                    <a key="list-loadmore-more" onClick={() => this.setState({showResetModal: true})}>{window.language.subscribe.reset}</a>
                                ]}>
                                <List.Item.Meta
                                    avatar={<img src={require('../images/flags/SQ.png')} width={32}/>}
                                    title={<span className={styles.country_name}>{window.language.subscribe.subscribe_url}</span>}
                                    description={<span className={styles.share_link}>{this.state.account.subscribeUrl}</span>}
                                />
                            </List.Item>

                        </div>
                        {/*<div className={styles.split_line}/>
                        <h4>{window.language.subscribe.backup_nodes}</h4>
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.dataList}
                            renderItem={item => (
                                <List.Item
                                    actions={[
                                        <a key="list-loadmore-edit" onClick={() => this.copyText(item.shareLink)}>{window.language.copy}</a>,
                                        <a key="list-loadmore-more" onClick={() => this.showQrcode(item.shareLink)}>{window.language.qrcode}</a>
                                    ]}>
                                    <List.Item.Meta
                                        avatar={<img src={require('../images/flags/' + item.countryCode + '.png')} width={32}/>}
                                        title={<span className={styles.country_name}>{item.countryName}</span>}
                                        description={<span className={styles.share_link}>{item.shareLink}</span>}
                                    />
                                </List.Item>
                            )}
                        />*/}
                        <Modal
                            width={350}
                            closable={false}
                            keyboard={true}
                            maskClosable={true}
                            destroyOnClose={true}
                            footer={null}
                            visible={this.state.showQrcodeModal}
                            onCancel={() => this.setState({showQrcodeModal: false})}>
                            <div className="modal_close">
                                <Icon type="close-circle" onClick={() => this.setState({showQrcodeModal: false})}/>
                            </div>
                            <div className={styles.qrcode_view}>
                                <QRCode className={styles.qrcode} value={this.state.qrcodeUrl} size={180}/>
                            </div>
                        </Modal>
                        <Modal
                            title={window.language.subscribe.reset_url}
                            visible={this.state.showResetModal}
                            onOk={() => this.resetSubscribe()}
                            onCancel={() => this.setState({showResetModal: false})}>
                            <p>{window.language.subscribe.reset_tip}</p>
                        </Modal>
                    </div>
                </Spin>
            </div>
        );
    }


}


