import React from 'react';
import styles from '../styles/register-apple-id.module.scss';
import 'antd/dist/antd.css';
import {Menu, Layout, Popover, Avatar, Switch, Button } from 'antd';
import BaseComponent from "../components/BaseComponent"


const { Header, Footer, Content } = Layout;

export default class RegisterAppleID extends BaseComponent {


    constructor(props) {
        super(props);
        this.state = {
            showLoginRegisterModal: false,
            showPaymentModal: false,
            showCreditCardModal: false,
            selectedPackage: {},
            isLogin: false
        }
    }

    render() {

        return (
            <div id="container" className={styles.container}>
                <Content className={styles.content_container}>
                   
                  <div className={styles.sk_logo}><img src={require('../images/icon-app-store.png')}/></div>
                  <div className={styles.title_text} id='title'>如何注册海外苹果ID（香港版）</div>

                  <div className={styles.body_text}>
                      本教程由松鼠VPN团队推出，2020年2月测试有效。
                      <br/>
                  </div>
                  
                  {/* step 1 */}
                  <div className={styles.body_text}>
                      <br/><br/>1. 前往 <a id='appleid-url' href="https://appleid.apple.com" target="_blank">https://appleid.apple.com</a>，点击“创建您的Apple ID”
                  </div>
                  <div className={styles.screenshot} id="screenshot1">
                      <img src={require("../images/HK-id/1.png")}/>
                  </div>
                 
                  {/* step 2 */}
                  <div className={styles.body_text}>
                      <br/><br/> <br/><br/>
                      2. 填写账户资料。国家和地区请选择香港。email一定要填未注册过苹果ID的。
                          <br/>
                  </div>
                  <div className={styles.screenshot} id="screenshot2">
                      <img src={require("../images/HK-id/2.png")}/>
                  </div>
                  
                  {/* step 3 */}
                  <div className={styles.body_text}>
                      <br/><br/><br/><br/>
                      3. 注册完成之后, “编辑”付款和送货信息。<br/>
                  </div>
                  <div className={styles.screenshot}>
                      <img src={require("../images/HK-id/3.png")}/>
                  </div>
                  <div className={styles.body_text}>
                      <br/><br/>
                      以下是香港区模板，照填即可。填写后点击“存储”保存。<br/>
                  </div>
                  <div className={styles.small_text}>
                      （如发现该地址无效，可填写其他有效的香港地址，eg.百度一家香港麦当劳的地址）<br/>
                   </div>
                  <div className={styles.screenshot}>
                      <img src={require("../images/HK-id/4.png")}/>
                  </div>
                 
                  {/* step 4 */}
                  <div className={styles.body_text}>
                      <br/><br/><br/><br/>
                      4.登录刚刚注册的账号，在App Store开始下载，首次下载时需要完成设置。点击“Review”。<br/>
                  </div>
                  <div className={styles.screenshot}>
                      <img src={require("../images/HK-id/5.png")}/>
                  </div>
                  <br/><br/>
                  <div className={styles.screenshot}>
                      <img src={require("../images/HK-id/6.png")}/>
                  </div>
                  <div className={styles.body_text}>
                      <br/><br/>
                  因为前边网页注册的时候填写了付款和收货信息，所以这里就自动填充了，点击“Next”就完成了。
                  如果前边没填写的话，这里就需要补充。
                  </div>
                  <div className={styles.screenshot}>
                      <img src={require("../images/HK-id/7.png")}/>
                  </div>
                     
               </Content>
            </div>
        );
    }


}


