import React from 'react'
import PropTypes from "prop-types"
import styles from '../../styles/modal/login-modal.module.scss'
import 'antd/dist/antd.css'
import {Icon, Modal} from 'antd'
import StorageUtils from "../../tools/StorageUtils"
import QRCode from "qrcode.react";

export default class UserInfoModal extends React.Component {

    static propTypes = {
        visible: PropTypes.bool,
        userInfo: PropTypes.object,
        onRequestClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    formatTime(expireTime) {
        if (expireTime) {
            let dateTime = new Date(expireTime.replace(/-/g, "/"))
            let originalMonth = dateTime.getMonth() + 1
            let month = originalMonth > 9 ? originalMonth : '0' + originalMonth
            let day = dateTime.getDate() > 10 ? dateTime.getDate() : '0' + dateTime.getDate()
            let finalTime;
            if (StorageUtils.getLanguage() === window.constants.LANGUAGE_CN) {
                finalTime = dateTime.getFullYear() + '-' + month + "-" + day
            } else {
                finalTime = month + "-" + day + "-" + dateTime.getFullYear()
            }
            return finalTime
        }
        return '';
    }


    render() {
        return (
            <Modal
                closable={false}
                keyboard={true}
                maskClosable={true}
                destroyOnClose={true}
                footer={null}
                visible={this.props.visible}
                onCancel={() => this.props.onRequestClose()}>
                <div className="modal_close">
                    <Icon type="close-circle" onClick={() => this.props.onRequestClose()}/>
                </div>
                <div className={styles.login}>
                    <img className={styles.logo_image} src={require('../../images/logo.png')}/>
                    <div className={styles.logo_title}>SquirrelVPN</div>
                    <div className={styles.form_item}>
                        <table style={{margin: '0 auto',textAlign:'left'}}>
                            <tbody>
                                <tr>
                                    <td style={{textAlign: 'right'}}>{window.language.reg_account}：</td>
                                    <td> {this.props.userInfo.email}</td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop: 10, textAlign: 'right'}}>{window.language.expire_time}：</td>
                                    <td style={{paddingTop: 10}}>{this.formatTime(this.props.userInfo.expireTime)}</td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop: 10, textAlign: 'right'}}>{window.language.invitation_code}：</td>
                                    <td style={{paddingTop: 10}}>{this.props.userInfo.invitationCode}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className={styles.qrcode_box}>
                                            <QRCode value={`${window.constants.INVITATION_LINK}${this.props.userInfo.invitationCode}`}
                                                    size={140}
                                                    className={styles.qrcode_core}
                                                    fgColor={'#5340dd'}
                                                    imageSettings={{
                                                        src: require('../../images/icon-download.png'),
                                                        height: 24,
                                                        width: 24,
                                                    }}/>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        );
    }

}
