import styles from './BgAnimations.module.css';
import React from 'react';

export default class BgAnimations extends React.Component {

  render() {
    return (
      <div id="bg_animation_container" className={styles.bg_animation_container}>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
        <div className={styles.circle_container}>
            <div className={styles.circle}></div>
        </div>
    </div>
    )
  }
}
