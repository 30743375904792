import React from 'react';
import PropTypes from "prop-types";
import styles from '../../styles/modal/payment-modal.module.scss';
import 'antd/dist/antd.css';
import {Button, message, Modal, Icon, Radio} from 'antd';
import WebServerRestClient from "../../tools/WebServerRestClient";
import StorageUtils from "../../tools/StorageUtils";
import CommonUtils from "../../tools/CommonUtils";

export default class CryptoCoinModal extends React.Component {

    static propTypes = {
        visible: PropTypes.bool,
        history: PropTypes.object,
        productDetail: PropTypes.object,
        loginCallback: PropTypes.func,
        selectionCallback: PropTypes.func,
        onRequestClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            selectedCoin: '',
            selectedBlockchain: ''
        }
    }

    selectCoinItem(blockchain, coin) {
        this.setState({selectedBlockchain: blockchain, selectedCoin: coin})
        //hide blockchain list
    }

    selectCoinType(coin) {
        this.setState({selectedCoin: coin})
    }

    selectBlockchain = e => {
        this.setState({selectedBlockchain: e.target.value})
    }

    isStableCoinButtonEnabled() {
       return (this.state.selectedCoin == 'USDT' || this.state.selectedCoin == 'USDC') && (this.state.selectedBlockchain);
    }

    isOtherCoinButtonEnabled() {
       return this.state.selectedCoin == 'SHIB' || this.state.selectedCoin == 'HIP';
    }

    showChainRadios() {
        return (this.state.selectedCoin == 'USDT' || this.state.selectedCoin == 'USDC');
    }

    selectPaymentMethod(method) {
        if (!StorageUtils.hasToken()) {
            // need to login
            this.props.loginCallback();
            return;
        }

        if (method === 'coinpayment') {
            this.setState({processing_coinpayment: true});
            new WebServerRestClient().POST('/user/payment/create-coinpayment-order', {
                purchasePlanId: this.props.productDetail.id,
                paymentWay: 12,
                userId: StorageUtils.getUserId()
            }).then(response => {
                CommonUtils.log("create-coinpayment-order response: " + JSON.stringify(response));
                this.setState({processing_coinpayment: false});
                if (response.code === 200) {
                    this.setState({coinpaymentOrderId: response.data});
                    document.getElementById('coinPaymentForm').submit();
                } else if (response.code === 401 || response.code === 403) {
                    StorageUtils.remove(window.constants.CURRENT_USER);
                    // need to login
                    this.props.loginCallback();
                } else {
                    message.warn(response['msg']);
                }
            }).catch(err => {
                this.setState({processing_coinpayment: false});
                message.error(err.message);
            });
        }


        // cryptos: SHIB , HIP, USDT, USDC
        if (method === 'crypto'){
            this.setState({processing_crypto: true});
            new WebServerRestClient().POST('/user/payment/create-crypto-order', {
                purchasePlanId: this.props.productDetail.id,
                userId: StorageUtils.getUserId(),
                blockchain: this.state.selectedBlockchain,
                tokenName: this.state.selectedCoin
            }).then(response => {
                CommonUtils.log("create-crypto-order response: " + JSON.stringify(response));
                this.setState({processing_crypto: false});
                if (response.code === 200) {
                    this.props.history.push('/views/cryptopayment/' + response.data.id);
                } else if (response.code === 401 || response.code === 403) {
                    StorageUtils.remove(window.constants.CURRENT_USER);
                    // need to login
                    this.props.loginCallback();
                } else {
                    message.warn(response['msg']);
                }
            }).catch(err => {
                this.setState({processing_crypto: false});
                message.error(err.message);
            });
        }
    }

    render() {
        let isChinese = StorageUtils.getLanguage() === window.constants.LANGUAGE_CN;
        let isMonthlyPlan = this.props.productDetail.id === 10001;
        const chainOptions = [
            { label: 'ERC20', value: 'ERC20' },
            { label: 'BSC', value: 'BSC' },
            // { label: 'HECO', value: 'HECO'},
        ];

        return (
            <Modal
                closable={false}
                keyboard={true}
                maskClosable={true}
                destroyOnClose={true}
                footer={null}
                visible={this.props.visible }
                onCancel={() => this.props.onRequestClose()}>
                <div className="modal_close">
                    <Icon type="close-circle" onClick={() => this.props.onRequestClose()}/>
                </div>
                <div className={styles.container}>
                    {isMonthlyPlan ? (
                            <div  className={styles.coin_title}>
                                {window.language.payment_modal.crypto_choose}
                            </div>
                        ) : (
                            <div  className={styles.coin_title}>
                                {window.language.payment_modal.crypto_choose_2}
                            </div>
                        )}

                    <br/>
                    <br/>
                    <div style={{}}>
                        <div  className={styles.coin_main_title}>{window.language.payment_modal.crypto_main}</div>
                        <img src={require('../../images/coin_list.png')} className={styles.coins_main}/>
                        <form id="coinPaymentForm" action="https://www.coinpayments.net/index.php" method="post" target="_blank">
                            <input type="hidden" name="cmd" value="_pay"/>
                            <input type="hidden" name="reset" value="1"/>
                            <input type="hidden" name="want_shipping" value="0"/>
                            <input type="hidden" name="merchant" value={window.constants.COIN_PAYMENT_MERCHANT}/>
                            <input type="hidden" name="item_name" value={isChinese ? this.props.productDetail.monthTextCN : this.props.productDetail.monthText}/>
                            <input type="hidden" name="item_number" value={this.props.productDetail.id}/>
                            <input type="hidden" name="currency" value="USD"/>
                            <input type="hidden" name="amountf" value={this.props.productDetail.amount}/>
                            <input type="hidden" name="custom" value={this.state.coinpaymentOrderId}/>
                            <input type="hidden" name="first_name" value={StorageUtils.getEmail()}/>
                            <input type="hidden" name="last_name" value=""/>
                            <input type="hidden" name="email" value={StorageUtils.getEmail()}/>
                            <input type="hidden" name="ipn_url" value={window.constants.COIN_PAYMENT_IPN_URL}/>
                            <Button type="primary" className={styles.payment_coin_button}
                                    loading={this.state.processing_coinpayment}
                                    onClick={() => this.selectPaymentMethod('coinpayment')}>
                                <span style={{fontSize: "14px", verticalAlign: "middle"}}>
                                  {window.language.payment_modal.crypto_coinpayment}
                              </span>
                            </Button>
                        </form>
                    </div>

                    <br/><br/>
                    <div>
                        <div  className={styles.coin_main_title}>{window.language.payment_modal.crypto_stable}</div>
                        <div className={styles.coin_item_list}>
                            <span
                                className={this.state.selectedCoin === 'USDT' ? styles.coin_item_selected : styles.coin_item}
                                onClick={() => this.selectCoinType('USDT')}>
                                <img src={require('../../images/coins/USDT.png')} className={styles.coin_item_logo}/>
                                <div className={styles.coin_item_name}>USDT</div>
                            </span>
                            <span
                                className={this.state.selectedCoin === 'USDC' ? styles.coin_item_selected : styles.coin_item}
                                onClick={() => this.selectCoinType('USDC')}>
                                <img src={require('../../images/coins/USDC.png')} className={styles.coin_item_logo}/>
                                <div className={styles.coin_item_name}>USDC</div>
                            </span>
                        </div>
                        <div className={(this.showChainRadios()? styles.chain_radios_show : styles.chain_radios_hidden)}>
                            <Radio.Group
                                options={chainOptions}
                                onChange={this.selectBlockchain}
                                value={this.state.selectedBlockchain}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </div>
                        <div style={{clear: "both"}}></div>
                        <div>
                            <Button className={(!this.isStableCoinButtonEnabled()) ? styles.payment_coin_button_disabled : styles.payment_coin_button}
                                    disabled={(!this.isStableCoinButtonEnabled())}
                                    loading={this.state.processing_crypto}
                                    onClick={() => this.selectPaymentMethod('crypto')}>
                                    <span style={{fontSize: "14px", verticalAlign: "middle"}}>
                                        {window.language.payment_modal.crypto_recommend}
                                    </span>
                            </Button>
                        </div>
                    </div>
                    <br/><br/>


                    <div>
                        <div  className={styles.coin_main_title}>{window.language.payment_modal.crypto_others}</div>
                        <div className={isMonthlyPlan ? styles.coin_item_list : styles.coin_item_list_narrow}
                             style={{width: 70}}>
                            {/* <span style={{marginLeft: 0}}
                                className={this.state.selectedCoin === 'USDT' ? styles.coin_item_selected : styles.coin_item}
                                onClick={() => this.selectCoinItem('BSC', 'USDT')}>
                                <img src={require('../../images/coins/USDT.png')} className={styles.coin_item_logo}/>
                                <div className={styles.coin_item_name}>USDT</div>
                            </span>
                            <span
                                className={this.state.selectedCoin === 'BGAS' ? styles.coin_item_selected : styles.coin_item}
                                onClick={() => this.selectCoinItem('ERC20', 'BGAS')}>
                                <img src={require('../../images/coins/BGAS.png')} className={styles.coin_item_logo}/>
                                <div className={styles.coin_item_name}>BGAS</div>
                            </span> */}
                            <span
                                style={{marginLeft: 0}}
                                className={this.state.selectedCoin === 'SHIB' ? styles.coin_item_selected : styles.coin_item}
                                onClick={() => this.selectCoinItem('ERC20', 'SHIB')}>
                                <img src={require('../../images/coins/SHIB.png')} className={styles.coin_item_logo}/>
                                <div className={styles.coin_item_name}>SHIB</div>
                            </span>
                            {/*<span className={isMonthlyPlan ? styles.show_hip : styles.hide_hip}>
                                <span
                                    className={this.state.selectedCoin === 'HIP' ? styles.coin_item_selected : styles.coin_item}
                                    onClick={() => this.selectCoinItem('BSC', 'HIP')}>
                                    <img src={require('../../images/coins/HIP.png')} className={styles.coin_item_logo}/>
                                    <div className={styles.coin_item_name}>HIP</div>
                                </span>
                            </span>*/}

                        </div>
                        <div style={{clear: "both"}}></div>
                        <div>
                            <Button className={(!this.isOtherCoinButtonEnabled()) ? styles.payment_coin_button_disabled : styles.payment_coin_button}
                                    disabled={(!this.isOtherCoinButtonEnabled())}
                                    loading={this.state.processing_crypto}
                                    onClick={() => this.selectPaymentMethod('crypto')}>
                                    <span style={{fontSize: "14px", verticalAlign: "middle"}}>
                                        {window.language.payment_modal.crypto_recommend}
                                    </span>
                            </Button>
                        </div>
                    </div>
                    <br/>
                    <p className={styles.link}><a href="/blog/2021/07/02/how-to-buy-BTC/" target="_blank"> {window.language.payment_modal.crypto_tuturial}</a></p>
                    <p className={styles.link}><a href="/blog/2021/07/24/pay-with-USDT/" target="_blank"> {window.language.payment_modal.crypto_tuturial_2}</a></p>

                </div>
            </Modal>
        );
    }

}
