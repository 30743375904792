import React from 'react'
import 'antd/dist/antd.css'
import styles from '../styles/modal/reset-password-modal.module.scss'
import {Alert, Button, Input, message, Result} from 'antd'
import WebServerRestClient from "../tools/WebServerRestClient"
import PropTypes from "prop-types"
import BaseComponent from "../components/BaseComponent"

let sendEmailTimer
export default class CloseAccount extends BaseComponent {

    static propTypes = {
        visible: PropTypes.bool,
        onResetPassword: PropTypes.func,
        onRequestClose: PropTypes.func
    }

    constructor(props) {
        super(props)
        this.state = {
            countdown: 45,
            email: '',
            password: '',
            validationCode: '',
            inviteCode: '',
            sending: false,
            btnSendDisabled: false,
            showMessageDialog: false,
            isDeleted: false
        }
    }

    componentDidMount() {
        let refCode = this.props.match.params.refCode
        if (!refCode) {
            refCode = this.getQueryString('ref')
        }
        this.setState({inviteCode: refCode})
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({[name]: value})
    }

    handleInputFocus(event) {
        const target = event.target
        if (target.type === 'text' || target.type === 'password') {
            this.setState({showMessageDialog: false})
        }
    }

    sendEmailCode() {
        let email = this.state.email
        if (email === '') {
            this.setState({showMessageDialog: true, message: "Please enter your email address"})
            return false
        }
        try {
            this.setState({sending: true})
            new WebServerRestClient().POST('/open/send-email', {
                email: email,
                code: 1001,
            }).then(response => {
                if (response.code === 200) {
                    sendEmailTimer = setInterval(() => {
                        this.sendTimer()
                    }, 1000)
                    this.setState({sending: false, btnSendDisabled: true})
                    message.success(window.language.send_successfully)
                } else {
                    message.warn(response['msg'])
                }
            }).catch(err => {
                this.setState({sending: false})
                message.error("Error: " + err.message)
            })
        } catch (err) {
            message.error("Error: " + err.message)
        }
    }

    sendTimer() {
        let countdown = this.state.countdown
        if (countdown === 0) {
            this.setState({btnSendDisabled: false, countdown: 45})
            clearInterval(sendEmailTimer)
        } else {
            countdown--
            this.setState({countdown: countdown})
        }
    }

    register() {
        this.setState({showMessageDialog: false})
        let email = this.state.email
        if (email === '') {
            this.setState({showMessageDialog: true, message: "Please enter your email address"})
            return false
        }
        let validationCode = this.state.validationCode
        if (validationCode === '') {
            this.setState({showMessageDialog: true, message: "Please enter verification code"})
            return false
        }
        this.setState({processing: true})
        new WebServerRestClient().POST('/open/close-account', {
            email: email,
            validationCode: validationCode
        }).then(response => {
            this.setState({processing: false})
            if (response.code === 200) {
                this.setState({isDeleted: true})
                message.success('Message submitted');
            } else {
                message.warning(response.msg)
            }
        }).catch(err => {
            this.setState({processing: false})
            message.error(err.message)
        })
    }

    render() {
        return (
            this.state.isDeleted ? this.deletedResult() : this.mainForm()
        )
    }

    mainForm(){
        return (
            <div className={styles.login} style={{padding: 30}}>
                <div className={styles.text_align}>
                    <img className={styles.logo_image} src={require('../images/logo.png')}
                         style={{cursor: "pointer"}}
                         onClick={() => this.props.history.push('/')}/>
                    <div className={styles.logo_title}>SquirrelVPN</div>
                </div>
                <div className={styles.form_item}>
                    <Input type={'text'} name={'email'} className={styles.form_input}
                           placeholder={'Email'}
                           autoCapitalize={'none'}
                           onFocus={this.handleInputFocus.bind(this)}
                           onChange={this.handleInputChange.bind(this)}/>
                </div>
                <div className={styles.form_item}>
                    <Input type={'text'} name={'validationCode'}
                           className={styles.form_input_code}
                           placeholder={'Verification code'}
                           autoCapitalize={'none'}
                           onFocus={this.handleInputFocus.bind(this)}
                           onChange={this.handleInputChange.bind(this)}/>
                    <Button
                        className={this.state.btnSendDisabled ? styles.form_button_sending : styles.form_button_sent}
                        loading={this.state.sending}
                        disabled={this.state.btnSendDisabled}
                        onClick={this.sendEmailCode.bind(this)}>
                        {this.state.countdown === 0 || this.state.countdown === 45 ? window.language.send_text : this.state.countdown + 'S'}
                    </Button>
                </div>
                <div className={styles.form_item}>
                    {this.state.showMessageDialog ?
                        <div className="message_box">
                            <Alert message={this.state.message} type="warning" closable={true}
                                   onClose={() => {
                                       this.setState({showMessageDialog: false})
                                   }}/>
                        </div>
                        :
                        null
                    }
                </div>
                <div className={styles.form_button_row}>
                    <Button className={styles.form_button} loading={this.state.processing}
                            onClick={this.register.bind(this)}>
                        {window.language.submit}
                    </Button>
                </div>
                <div className={styles.form_button_row} style={{marginTop: 10}}>
                        <span className={styles.link_download}
                              onClick={() => this.props.history.push('/')}>
                            {window.language.back_to_home}
                        </span>
                </div>
            </div>
        )
    }

    deletedResult(){
        return (
            <div className={styles.login} style={{padding: 30}}>
                <Result
                    status="success"
                    title={'Your account has been deleted'}
                />
            </div>
        )
    }

}
