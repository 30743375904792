import React from 'react'
import PropTypes from "prop-types"
import styles from '../../styles/modal/reset-password-modal.module.scss'
import 'antd/dist/antd.css'
import {Alert, Button, Icon, Input, message, Modal} from 'antd'
import WebServerRestClient from "../../tools/WebServerRestClient"
import StorageUtils from "../../tools/StorageUtils"

let sendEmailTimer;
export default class ChangeEmailModal extends React.Component {

    static propTypes = {
        visible: PropTypes.bool,
        onResetPassword: PropTypes.func,
        onRequestClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            countdown: 45,
            email: '',
            password: '',
            validationCode: '',
            sending: false,
            btnSendDisabled: false,
            showMessageDialog: false
        }
    }

    componentDidMount() {
        clearInterval(sendEmailTimer);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    handleInputFocus(event) {
        const target = event.target;
        if (target.type === 'text' || target.type === 'password') {
            this.setState({showMessageDialog: false});
        }
    }

    sendEmailCode() {
        let email = this.state.email;
        if (email === '') {
            this.setState({showMessageDialog: true, message: "Please enter your email address"});
            return false;
        }
        try {
            this.setState({sending: true})
            new WebServerRestClient().POST('/open/send-email', {
                email: email,
                code: 1001,
            }).then(response => {
                this.setState({sending: false})
                if (response.code === 200) {
                    sendEmailTimer = setInterval(() => {
                        this.sendTimer();
                    }, 1000);
                    this.setState({btnSendDisabled: true})
                    message.success(window.language.send_successfully);
                } else {
                    message.warn(response['msg']);
                }
            }).catch(err => {
                this.setState({sending: false})
                message.error("Error: " + err.message);
            });
        }catch (err) {
            message.error("Error: " + err.message);
        }
    }

    sendTimer() {
        let countdown = this.state.countdown
        if (countdown === 0) {
            this.setState({btnSendDisabled: false, countdown: 45})
            clearInterval(sendEmailTimer)
        } else {
            countdown--
            this.setState({countdown: countdown})
        }
    }

    changeEmail() {
        this.setState({showMessageDialog: false})
        let email = this.state.email
        if (email === '') {
            this.setState({showMessageDialog: true, message: "Please enter your email address"})
            return false
        }
        let validationCode = this.state.validationCode
        if (validationCode === '') {
            this.setState({showMessageDialog: true, message: "Please enter verification code"})
            return false
        }
        this.setState({processing: true})
        new WebServerRestClient().POST('/user/change-email', {
            userId: StorageUtils.getUserId(),
            email: email,
            validationCode: validationCode
        }).then(response => {
            this.setState({processing: false})
            if (response.code === 200) {
                message.success(window.language.change_email_successfully);
                let userInfo = {
                    userId: response.data.userId,
                    email: response.data.email,
                    ethAddress: response.data.ethAddress,
                    token: response.data.token
                };
                StorageUtils.saveUser(userInfo);
                window.CurrentUser = userInfo;

                clearInterval(sendEmailTimer);
                this.props.onRequestClose();
                this.props.onChangeEmail(userInfo);
            } else {
                this.setState({showMessageDialog: true, message: response['msg']})
            }
        }).catch(err => {
            this.setState({processing: false})
            message.error("Error: " + err.message)
        })
    }

    render() {
        return (
            <Modal
                closable={false}
                keyboard={true}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
                visible={this.props.visible}
                onCancel={() => this.props.onRequestClose()}>
                <div className="modal_close">
                    <Icon type="close-circle" onClick={() => this.props.onRequestClose()}/>
                </div>
                <div className={styles.login}>
                    <div className={styles.text_align}>
                        <img className={styles.logo_image} src={require('../../images/logo.png')}/>
                        <div className={styles.logo_title}>SquirrelVPN</div>
                    </div>
                    <div className={styles.form_item}>
                        <Input type={'text'} name={'email'} className={styles.form_input}
                               placeholder={'New Email'}
                               autoCapitalize={'none'}
                               onFocus={this.handleInputFocus.bind(this)}
                               onChange={this.handleInputChange.bind(this)}/>
                    </div>
                    <div className={styles.form_item}>
                        <Input type={'text'} name={'validationCode'}
                               className={styles.form_input_code}
                               placeholder={'Verification code'}
                               autoCapitalize={'none'}
                               onFocus={this.handleInputFocus.bind(this)}
                               onChange={this.handleInputChange.bind(this)}/>
                        <Button className={this.state.btnSendDisabled ? styles.form_button_sending : styles.form_button_sent}
                                loading={this.state.sending}
                                disabled={this.state.btnSendDisabled}
                                onClick={this.sendEmailCode.bind(this)}>
                            {this.state.countdown === 0 || this.state.countdown === 45 ? window.language.send_text : this.state.countdown + 'S'}
                        </Button>
                    </div>
                    <div className={styles.form_item}>
                        {this.state.showMessageDialog ?
                            <div className="message_box">
                                <Alert message={this.state.message} type="warning" closable={true}
                                       onClose={() => {
                                           this.setState({showMessageDialog: false})
                                       }}/>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className={styles.form_button_row}>
                        <Button className={styles.form_button} loading={this.state.processing}
                                onClick={this.changeEmail.bind(this)}>
                            {window.language.change_email}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

}
