import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './views/Home';
import Register from './views/Register';
import CryptoCoinPayment from './views/CryptoCoinPayment';
import UserNodeList from './views/UserNodeList';
import RegisterAppleID from './views/RegisterAppleID';
import CloseAccount from './views/CloseAccount';

export default class App extends React.Component {

  render(){
    return(
        <Router>
            <React.Suspense>
              <Switch>
                <Route exact path="/" render={props => <Home {...props}/>}/>
                <Route exact path="/home" render={props => <Home {...props}/>}/>
                <Route exact path="/how-to-register-apple-id" render={props => <RegisterAppleID {...props}/>}/>
                <Route exact path="/v/:refCode" render={props => <Register {...props}/>}/>
                <Route exact path="/views/register" render={props => <Register {...props}/>}/>
                <Route exact path="/views/cryptopayment/:orderId" render={props => <CryptoCoinPayment {...props}/>}/>
                <Route exact path="/views/nodes/:userId" render={props => <UserNodeList {...props}/>}/>
                <Route exact path="/views/close-account" render={props => <CloseAccount {...props}/>}/>
              </Switch>
            </React.Suspense>
        </Router>
    );
  }
}
