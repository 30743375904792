import React from 'react';
import PropTypes from "prop-types";
import styles from '../../styles/modal/login-modal.module.scss';
import 'antd/dist/antd.css';
import {Modal, Input, Alert, Button, message, Icon, Result} from 'antd';
import StorageUtils from "../../tools/StorageUtils";
import WebServerRestClient from "../../tools/WebServerRestClient";

export default class InvitationModal extends React.Component {

    static propTypes = {
        visible: PropTypes.bool,
        loginCallback: PropTypes.func,
        onRequestClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            inviteSuccess: false,
            inviteCode: '',
            password: '',
            showMessageDialog: false
        }
    }

    componentDidMount() {

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    handleInputFocus(event) {
        const target = event.target;
        if (target.type === 'text' || target.type === 'password') {
            this.setState({showMessageDialog: false});
        }
    }

    login() {
        this.setState({showMessageDialog: false});
        let inviteCode = this.state.inviteCode;
        let userId = StorageUtils.getUserId();
        if (inviteCode === '') {
            this.setState({showMessageDialog: true, message: "Please enter the invitation code"});
            return false;
        }
        if (!userId || userId.toString() === '') {
            this.setState({showMessageDialog: true, message: "Please login"});
            return false;
        }
        this.setState({processing: true});
        new WebServerRestClient().POST('/user/invite', {
            userId: userId,
            inviteCode: inviteCode
        }).then(response => {
            this.setState({processing: false});
            if (response.code === 200) {
                this.setState({inviteSuccess: true});
            } else {
                this.setState({showMessageDialog: true, message: response['msg']});
            }
        }).catch(err => {
            this.setState({processing: false});
            message.error("Error: " + err.message);
        });
    }

    render() {
        return (
            <Modal
                closable={false}
                keyboard={true}
                maskClosable={true}
                destroyOnClose={true}
                footer={null}
                visible={this.props.visible}
                onCancel={() => this.props.onRequestClose()}>
                <div className="modal_close">
                    <Icon type="close-circle" onClick={() => this.props.onRequestClose()}/>
                </div>
                <div className={styles.login} style={{display: this.state.inviteSuccess ? 'none' : 'block'}}>
                    <img className={styles.logo_image} src={require('../../images/logo.png')}/>
                    <div className={styles.logo_title}>SquirrelVPN</div>
                    <div className={styles.form_item}>
                        <Input type={'text'} name={'inviteCode'} className={styles.form_input}
                               placeholder={'Invitation Code'}
                               autoCapitalize={'none'}
                               onFocus={this.handleInputFocus.bind(this)}
                               onChange={this.handleInputChange.bind(this)}/>
                    </div>
                    <div className={styles.form_item}>
                        {this.state.showMessageDialog ?
                            <div className="message_box">
                                <Alert message={this.state.message} type="warning" closable={true}
                                       onClose={() => {
                                           this.setState({showMessageDialog: false})
                                       }}/>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className={styles.form_button_row}>
                        <Button className={styles.form_button} loading={this.state.processing}
                                onClick={this.login.bind(this)}>
                            {window.language.submit}
                        </Button>
                    </div>
                </div>

                <div className={styles.login} style={{display: this.state.inviteSuccess ? 'block' : 'none'}}>
                    <Result
                        status="success"
                        subTitle={window.language.redeem_success_sub_title}
                    />
                </div>
            </Modal>
        );
    }

}
